export enum ApiResourceStatus {
  Stopped,
  Pending,
  Found,
  NotFound,
  ServerError,
  ClientError,
}

export type CountryInfo = {
  alpha2Code: string;
  i18nKey: string;
  dialingCode: string;
};

const countries: { [key: string]: CountryInfo } = {
  DE: { i18nKey: "countries.DE", alpha2Code: "DE", dialingCode: "+49" },
  AT: { i18nKey: "countries.AT", alpha2Code: "AT", dialingCode: "+43" },
  BE: { i18nKey: "countries.BE", alpha2Code: "BE", dialingCode: "+32" },
  BG: { i18nKey: "countries.BG", alpha2Code: "BG", dialingCode: "+359" },
  HR: { i18nKey: "countries.HR", alpha2Code: "HR", dialingCode: "+385" },
  CY: { i18nKey: "countries.CY", alpha2Code: "CY", dialingCode: "+357" },
  CZ: { i18nKey: "countries.CZ", alpha2Code: "CZ", dialingCode: "+420" },
  DK: { i18nKey: "countries.DK", alpha2Code: "DK", dialingCode: "+45" },
  EE: { i18nKey: "countries.EE", alpha2Code: "EE", dialingCode: "+372" },
  FI: { i18nKey: "countries.FI", alpha2Code: "FI", dialingCode: "+358" },
  FR: { i18nKey: "countries.FR", alpha2Code: "FR", dialingCode: "+33" },
  EL: { i18nKey: "countries.EL", alpha2Code: "EL", dialingCode: "+30" },
  HU: { i18nKey: "countries.HU", alpha2Code: "HU", dialingCode: "+36" },
  IE: { i18nKey: "countries.IE", alpha2Code: "IE", dialingCode: "+353" },
  IT: { i18nKey: "countries.IT", alpha2Code: "IT", dialingCode: "+39" },
  LV: { i18nKey: "countries.LV", alpha2Code: "LV", dialingCode: "+371" },
  LT: { i18nKey: "countries.LT", alpha2Code: "LT", dialingCode: "+370" },
  LU: { i18nKey: "countries.LU", alpha2Code: "LU", dialingCode: "+352" },
  MT: { i18nKey: "countries.MT", alpha2Code: "MT", dialingCode: "+356" },
  NL: { i18nKey: "countries.NL", alpha2Code: "NL", dialingCode: "+31" },
  PL: { i18nKey: "countries.PL", alpha2Code: "PL", dialingCode: "+48" },
  PT: { i18nKey: "countries.PT", alpha2Code: "PT", dialingCode: "+351" },
  RO: { i18nKey: "countries.RO", alpha2Code: "RO", dialingCode: "+40" },
  RS: { i18nKey: "countries.RS", alpha2Code: "RS", dialingCode: "+381" },
  SK: { i18nKey: "countries.SK", alpha2Code: "SK", dialingCode: "+421" },
  SI: { i18nKey: "countries.SI", alpha2Code: "SI", dialingCode: "+386" },
  ES: { i18nKey: "countries.ES", alpha2Code: "ES", dialingCode: "+34" },
  SE: { i18nKey: "countries.SE", alpha2Code: "SE", dialingCode: "+46" },
  GB: { i18nKey: "countries.GB", alpha2Code: "GB", dialingCode: "+44" },
};

export type CountryCode = Extract<keyof typeof countries, string>;
export const COUNTRY_DICT = countries;
export const COUNTRY_LIST = Object.values(countries);
export const DEFAULT_COUNTRY_CODE: CountryCode = "DE";
export const DEFAULT_COUNTRY = COUNTRY_DICT[DEFAULT_COUNTRY_CODE];

/**
 * Transform a float number representing an amount in a currency to equivalent in cents.
 * Alternative could be to use https://mikemcl.github.io/decimal.js/
 * @param x
 */
export function financialCents(x: number): number {
  const inCents = (x * 100).toFixed(0);
  return Number.parseInt(inCents, 10);
}
