import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import EnTranslation from "./translations/en/translation.json";
import DeTranslation from "./translations/de/translation.json";
import NlTranslation from "./translations/nl/translation.json";

interface Language {
  nativeName: string;
}
interface SupportedLanguages {
  [index: string]: Language;
}

interface LegacyNavigator extends Navigator {
  userLanguage?: string;
  browserLanguage?: string;
  systemLanguage?: string;
}

export const lngs: SupportedLanguages = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};

export const resources = {
  en: { EnTranslation },
  de: { DeTranslation },
  nl: { NlTranslation },
} as const;

export function getBrowserLocales(): string[] {
  let browserLocales: string[] = [];

  if (typeof navigator !== "undefined") {
    if (navigator.languages) {
      browserLocales = [...navigator.languages];
    } else if ((navigator as LegacyNavigator).userLanguage) {
      browserLocales.push((navigator as LegacyNavigator).userLanguage || "en");
    } else if ((navigator as LegacyNavigator).systemLanguage) {
      browserLocales.push(
        (navigator as LegacyNavigator).systemLanguage || "en"
      );
    } else if ((navigator as LegacyNavigator).language) {
      browserLocales.push((navigator as LegacyNavigator).language);
    }
  } else {
    // Fallback to English if no navigator
    return ["en"];
  }

  // Fallback to English if no locale detected
  if (browserLocales.length === 0) {
    return ["en"];
  }

  return browserLocales.map((locale) => {
    return locale.split(/-|_/)[0];
  });
}

let isInitialized = false;

i18n.on("languageChanged", () => {
  document.body.dir = i18n.dir();
});

export const initI18n = (): void => {
  if (!isInitialized) {
    isInitialized = true;
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: "en",
        lng: getBrowserLocales()[0],
        interpolation: {},
        load: "languageOnly",
        react: {
          useSuspense: true,
          transSupportBasicHtmlNodes: true,
          transKeepBasicHtmlNodesFor: ["br", "strong", "a"],
        },
        backend: {
          queryStringParams: { v: process.env.commit_hash || "dev" },
        },
        returnNull: false,
      });
  }
};

export const language2LetterCode = (): string => {
  const { language } = i18n;
  return language.slice(0, 2);
};

export { default as i18n } from "i18next";
