export enum ApiResourceStatus {
  Stopped,
  Pending,
  Found,
  NotFound,
  ServerError,
  ClientError,
}

export type CountryCode = "DE" | "AT" | "NL";
export const DEFAULT_COUNTRY_CODE = "DE";

/**
 * Transform a float number representing an amount in a currency to equivalent in cents.
 * Alternative could be to use https://mikemcl.github.io/decimal.js/
 * @param x
 */
export function financialCents(x: number): number {
  const inCents = (x * 100).toFixed(0);
  return Number.parseInt(inCents, 10);
}
